<template>
  <v-responsive>
    <SubMenuLottowinMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuLottowinMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuLottowin></SubMenuLottowin>
          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>

          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>

          
          <v-sheet min-height="53vh">
            <!--  -->


          <v-responsive class="mt-10">
              <h3>당첨금 지급장소</h3>
              <v-sheet outlined class=" mt-1 ">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            당첨금		
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            지급장소
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            준비물
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">1등		</td>
                          <td class="text-center">농협은행 본점</td>
                          <td class="text-center">당첨복권,신분증</td>
                        </tr>
                        <tr>
                          <td class="text-center">2등, 3등	</td>
                          <td class="text-center">농협은행 각 지점</td>
                          <td class="text-center">당첨복권,신분증</td>
                        </tr>
                        <tr>
                          <td class="text-center">4등, 5등		</td>
                          <td class="text-center">일반 판매점과 농협은행 각 지점</td>
                          <td class="text-center">당첨복권</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>


          <v-responsive class="mt-10">
              <h3>인터넷 구입</h3>
              <v-sheet outlined class=" mt-1 ">
                  <v-simple-table >
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            당첨금		
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            지급장소
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            준비물
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">1등		</td>
                          <td class="text-center">농협은행 본점</td>
                          <td class="text-center">당첨복권,신분증</td>
                        </tr>
                        <tr>
                          <td class="text-center">2등, 3등	</td>
                          <td class="text-center">농협은행 각 지점</td>
                          <td class="text-center">당첨복권,신분증</td>
                        </tr>
                        <tr>
                          <td class="text-center">4등, 5등		</td>
                          <td class="text-center">동행복권 사이트	</td>
                          <td class="text-center">예치금계좌 자동지급</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>



          <v-responsive class="mt-10">
<ul class="list_text_common">
                            <li>당첨금은 농협은행 영업점에서만 지급받으실 수 있습니다. (지역농·축협 제외)</li>
                            <li>로또 복권 당첨금 지급업무 미처리 영업점 안내 : 기업금융지점, 대기업RM센터</li>
                            <li><strong>인터넷 구입 시, 1등/2등/3등이 당첨된 경우 고액당첨내역 페이지에서 실명확인 후, 복권번호와 신분증을 지참하여 농협은행을 방문하시면 됩니다.</strong></li>
                            <li><strong>인터넷 구입 시, 4등과 5등이 당첨된 경우 추첨일 기준 다음날 06:00시부터 예치금 계좌로 자동 지급됩니다.</strong></li>
                            <li>예치금은 고객 요청에 따라 본인 명의의 금융계좌로 지급 가능합니다.</li>
                        </ul>
          </v-responsive>


          <v-responsive class="mt-10">
              <h3>농협은행 본점 안내</h3>
              
              <v-img
                class="shrink"
                contain
                src="https://m.dhlottery.co.kr/images/mobile/content/img_map.jpg"
                full-width
              />
              <div class="detail mt-5">
                        <ul>
                            <li>주소 : 서울시 중구 충정로 1가 85번지 15층 농협은행 복권사업팀</li>
                            <li>문의전화 :  1588-6450</li>
                            <li class="transit"><strong>지하철 교통편</strong><p>지하철 5호선 서대문역 5번 출구에서 광화문 사거리 방향, 도보 2분 내외 </p></li>
                        </ul>
                    </div>
              

          </v-responsive>


          <v-responsive class="mt-10">
              <h3>지급기한 및 기타사항</h3>
              
             <div class="sub_cont mt-5">
                        <h5 class="tit_dep5">지급기한</h5>
                        <div class="detail">
                            <ul>
                                <li>지급기한은 해당 회차 지급개시일로부터 1년까지입니다.</li>
                                <li>지급기한일이 공휴일 또는 토, 일요일인 경우 익 영업일(은행 영업기준)에 청구가 가능합니다. </li>
                                <li>지급기한일 초과 시 당첨자가 미 수령한 당첨금액은 복권기금으로 귀속됩니다. </li>
                            </ul>
                        </div>
                    </div>

                    <div class="sub_cont mt-5">
                        <h5 class="tit_dep5">기타사항</h5>
                        <div class="detail">
                            <ul>
                                <li>복권을 분실하였을 경우 당첨금 지급이 불가능합니다.</li>
                                <li>복권이 훼손된 경우 복권의 1/2이상 원형이 보존되고 컴퓨터 인식이 가능한 복권에 한하여 당첨금을 지급합니다.</li>
                                <li>당첨자가 청소년(만 19세 미만)일 경우 당첨금 지급이 불가합니다.</li>
                                <li>당첨금 지급은 일시불로 지급됩니다.</li>
                                <li>3등 이상의 당첨금은 농협은행에서 당첨자 인적사항을 확인하고 제세금을 원천징수 공제한 후 지급합니다.</li>
                                <li>제세금 등록을 위하여 신원을 확인할 실명확인증표를 지참하시고 당첨금을 청구하여야 합니다.</li>
                                <li>4등, 5등 당첨금은 현금 지급요청 시, 현금으로 지급됩니다.</li>
                            </ul>
                        </div>
                    </div>

              

          </v-responsive>

              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>




          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "LottoWin",

  components: {
    //HelloWorld,
  },
  data: () => ({
    selectedItem: 0,
    breadcrumbs: [
      { text: "홈" },
      { text: "당첨결과 분석" },
      { text: "당첨금 지급안내" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
